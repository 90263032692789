const globalColors = {
  white: "#ffffff",
  whiteHover: "#F2F2F2",

  gray: "#333333",
  grayMain: "#444444",
  grayTextInput: "#666666",
  grayTextInputHover: "#F9F9F9",
  grayLight: "#CCCCCC",
  veryLightGrey: "#aaaaaa",
  bgGray: "#F5F5F5",
  textGray: "#808080",
  tagColor: "#efefef",
  tagHover: "#E2E2E2",

  orangeMain: "#ff6f3d",
  orangeHover: "#ff865c",
  orangeLetters: "#ff642e",

  green: "3db80f"
};

export default globalColors;
