const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  mobile: "592px",
  tablet: "768px",
  laptopS: "1000px",
  laptop: "1024px",
  laptopM: "1190px",
  laptopL: "1440px",
  desktop: "1920px",
  desktopL: "2560px",
};

export const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  mobile: `(max-width: ${size.mobile})`,
  tablet: `(max-width: ${size.tablet})`,
  laptopS: `(max-width: ${size.laptopS})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopM: `(max-width: ${size.laptopM})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(max-width: ${size.desktopL})`,
};
